import React, { useEffect, useState } from 'react';
import Layout from "../../components/shared-layout";
import useStores from "../../hooks/use-stores";
import { observer } from 'mobx-react';
import {
  Accordion, AccordionDetails, AccordionSummary, Button, MenuItem, Modal, Select, Tab, TableCell, Tabs, TextField
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CustomisedModal from '../../components/customised-modal';
import PermissionSetting from '../../components/permission-setting';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import SaveIcon from '@material-ui/icons/Save';
import { THEME_CLASS, THEME_NAME_CONVERSION, useStyles } from '../../constants/style';
import { toJS } from 'mobx';
import LoadingPanel from '../../components/loading-panel';
import PublishIcon from '@material-ui/icons/Publish';
import TabPanel from '../../components/tab-panel';
import { navigate } from 'gatsby-link';
import PermissionGuard from '../../components/permission-guard';
import { useTranslation } from 'react-i18next';
import VMCard from '../../components/collapsed-card';
import { getPermissionValidity, PermissionCategory } from '../../constants/options';
import PermissionTemplate from '../../components/permission-template';
import ClearIcon from '@material-ui/icons/Clear';
function tabsProps(index: number, activeTab: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    className: `normal-case ${activeTab ? 'text-barPrimary font-bold' : 'text-black'} `
  };
}
function permissionTabsProps(index: number, activeTab: any) {
  return {
    id: `permission-tab-${index}`,
    'aria-controls': `permission-tabpanel-${index}`,
    className: `normal-case ${activeTab ? 'text-barPrimary font-bold' : 'text-black'} `
  };
}

const OnCreateUserRoleLayout = (({
  setShowModal, callbackAfterSaved,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { rootStore, collegeStore } = useStores();
  const [tabValue, setTabValue] = useState<number>(0);
  const [activedRole, setActivedRole] = useState<number>(0);
  const [activedRoleName, setActivedRoleName] = useState<string>("");
  const [addUserRole, setAddUserRole] = useState<string>("");
  const [pmsLevel, setPmsLevel] = useState<string>("");

  const onChangeUserRole = (event: React.ChangeEvent<{ value: unknown }>) => {
    setActivedRole(event.target.value as number);
    let temp = collegeStore.permissionList.filter((pl) => pl.id === event.target.value as number);
    setActivedRoleName(temp[0].name);
  };

  const createNewUserRole = () => {
    if (addUserRole == "") {
      rootStore.notify("The field can not be empty!", 'warning');
      return;
    }
    setShowModal("");
    collegeStore.createUserRole({
      name: addUserRole,
      collegeId: collegeStore.collegeInfo.id
    }).then(() => {
      setShowModal("");
      setPmsLevel("");
      callbackAfterSaved();
      rootStore.notify("Permission Role Added!", 'success');
    }).catch((err) => {
      rootStore.notify("Error: " + err, 'error');
    })
  }

  const createPermissionStrategy = () => {
    if (pmsLevel == "") {
      rootStore.notify("The field can not be empty!", 'warning');
      return;
    }
    collegeStore.createPmsLevelByUserRole({
      name: pmsLevel,
      roleId: activedRole
    }).then(() => {
      rootStore.notify("Permission Strategy Added!", 'success');
      setShowModal("");
      setPmsLevel("");
      callbackAfterSaved();
    }).catch((err) => {
      rootStore.notify("Error: " + err, 'error');
    })
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <div className="border">
        <Tabs
          TabIndicatorProps={{ style: { display: "none" } }}
          value={tabValue}
          onChange={handleTabChange}
        >
          <Tab label={t('USER_ROLE')} {...permissionTabsProps(0, 0 === tabValue)} />
          <Tab label={t('PERMISSION_STRATEGY')} {...permissionTabsProps(1, 1 === tabValue)} />
        </Tabs>
      </div>

      <TabPanel value={tabValue} paddingValue={0} index={0}>
        <div className="px-20 py-10">
          <TextField
            label={t('USER_ROLE_NAME')}
            variant="outlined"
            value={addUserRole}
            className={`${classes.textField} w-full`}
            onChange={(value) => setAddUserRole(value.target.value)}
          />
          <div className="w-full text-right mt-10">
            <Button
              className="bg-buttonPrimary text-white px-8 py-2 mx-2"
              component="span"
              onClick={() => setShowModal("")}
            >
              {t('CANCEL')}
            </Button>
            <Button
              className="text-white bg-buttonPrimary px-8 py-2 mx-2"
              component="span"
              onClick={createNewUserRole}
            >
              {t('ADD_ROLE')}
            </Button>
          </div>
        </div>

      </TabPanel>

      <TabPanel value={tabValue} paddingValue={0} index={1}>
        <div className="px-20 py-10">
          <div className="text-barPrimary">1. {t('SELECT_EXISTING_USER_ROLE')}:</div>
          <Select
            labelId="demo-simple-select-label"
            value={activedRole}
            onChange={onChangeUserRole}
            id="demo-simple-select"
            className="bg-background w-full h-full p-2 px-4"
          >
            {collegeStore.permissionList && collegeStore.permissionList.map((r) => (
              <MenuItem key={r.id} value={r.id}>{r.name}</MenuItem>
            ))}
          </Select>
          {
            activedRole != 0 &&
            <>
              <div className="mt-8 mb-4 text-barPrimary">2. {t('CREATE_STRATEGY_FOR')} {activedRoleName}:</div>
              <TextField
                label={t('PERMISSION_STRATEGY_NAME')}
                variant="outlined"
                value={pmsLevel}
                className={`${classes.textField} w-full`}
                onChange={(value) => setPmsLevel(value.target.value)}
              />
              {/* <input
                className="p-2 rounded-md border-buttonPrimary border-2 w-full"
                value={pmsLevel}
                placeholder="Create a new permission strategy"
                onChange={(value) => setPmsLevel(value.target.value)}
              /> */}
            </>
          }
          <div className="w-full text-right mt-10">
            <Button
              className="bg-buttonPrimary text-white px-8 py-2 mx-2"
              component="span"
              onClick={() => setShowModal("")}
            >
              {t('CANCEL')}
            </Button>
            <Button
              className="text-white bg-buttonPrimary px-8 py-2 mx-2"
              component="span"
              onClick={createPermissionStrategy}
            >
              {t('ADD_STRATEGY')}
            </Button>
          </div>
        </div>
      </TabPanel>
    </>
  )

});

const GeneralPage = observer(() => {
  const { collegeStore, rootStore, userStore } = useStores();
  const { t } = useTranslation();
  const classes = useStyles();
  const headingStyle = "text-barPrimary p-4 text-lg 2xl:text-2xl font-bold uppercase";
  const [collegeName, setCollegeName] = useState("");
  const [logoFile, setLogoFile] = useState<any>();
  const [logoPreview, setLogoPreview] = useState<any>("");
  const [theme, setTheme] = useState("");
  const [applyTheme, setApplyTheme] = useState("");
  const [emailServer, setEmailServer] = useState("");
  const [emailAccount, setEmailAccount] = useState("");
  const [emailPwd, setEmailPwd] = useState("");
  const [showModal, setShowModal] = useState("");
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [userRoleData, setUserRoleData] = useState([]);
  const [roleId, setRoleId] = useState<number>(0);
  const [tabValue, setTabValue] = useState<number>(0);

  useEffect(() => {
    if (typeof window !== "undefined" && localStorage.COLLEGE) {
      collegeStore.getCollegeInfo(JSON.parse(localStorage.COLLEGE).collegeId).then(() => {
        setCollegeName(collegeStore.collegeInfo.name);
        console.log(toJS(collegeStore.collegeInfo));
        // let tempTheme = "";
        // for(var theme of THEME_NAME_CONVERSION){
        //   if(theme.css == collegeStore.collegeInfo.styleSetting){
        //     tempTheme = theme.default;
        //     break;
        //   }
        // }
        // console.log(tempTheme);
        setApplyTheme(localStorage.THEME);
        setTheme(collegeStore.collegeInfo.styleSetting);
        setEmailServer(collegeStore.collegeInfo.emailServer);
        setEmailAccount(collegeStore.collegeInfo.emailAccount);
        setEmailPwd('*******');
      });
    }

  }, []);

  const onCloseSaveModal = () => {
    setShowModal("");
    collegeStore.getPermissionListByCollege(
      typeof window !== "undefined" && localStorage.COLLEGE && JSON.parse(localStorage.COLLEGE).collegeId
    );
  }


  const onChangeTheme = (event: React.ChangeEvent<{ value: unknown }>) => {
    let selected = (event.target.value as string);
    for (var theme of THEME_NAME_CONVERSION) {
      if (theme.default == selected) {
        setApplyTheme(theme.css);
        break;
      }
    }
    setTheme(selected);
  }

  const uploadCollegeLogo = () => {
    if (collegeName == "") {
      rootStore.notify(`${t('PLEASE_FILL_IN_ALL_FIELDS')}!`, 'warning');
      return;
    }
    var formData = new FormData();
    formData.append("", logoFile);

    if (logoPreview != "") {
      collegeStore.uploadCollegeLogo(
        typeof window !== "undefined" && localStorage.COLLEGE && JSON.parse(localStorage.COLLEGE).collegeId,
        formData
      ).then(() => {
        setLogoFile("");
        setLogoPreview("");
        rootStore.notify(t('LOGO_UPLOADED'), 'success');
      })
    }

    collegeStore.updateCollegeInfo({
      id: typeof window !== "undefined" && localStorage.COLLEGE && JSON.parse(localStorage.COLLEGE).collegeId,
      name: collegeName,
    }).then(() => {
      rootStore.notify(t('LOGO_UPLOADED'), 'success');
    }).catch((err) => {
      rootStore.notify("Error:" + err, 'error');
    });
  }

  const updateCollegeInfo = () => {
    if (collegeName == "") {
      rootStore.notify(`${t('PLEASE_FILL_IN_ALL_FIELDS')}!`, 'warning');
      return;
    }
    collegeStore.updateCollegeInfo({
      id: typeof window !== "undefined" && localStorage.COLLEGE && JSON.parse(localStorage.COLLEGE).collegeId,
      styleSetting: theme,
      emailAccount: emailAccount,
      emailServer: emailServer,
      emailPassword: emailPwd == "*******" ? collegeStore.collegeInfo.emailPassword : emailPwd
    }).then(() => {
      rootStore.notify(t('SCHOOL_INFO_UPDATED'), 'success');
    }).catch((err) => {
      rootStore.notify("Error:" + err, 'error');
    });
  }

  const onModifyPermssions = (id) => {
    setRoleId(id);
    setUserRoleData(collegeStore.permissionList);
    setShowModal("modify");
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      {
        typeof window !== "undefined" &&
        <Layout
          showSeachStudent={true}
          showYearSemTerm={false}
          showClassCode={false}
          showDomain={false}
          showStaffList={false}
          showFilter={false}
          showNewsArea={true}
        >
          <title>{t('GENERAL')}</title>

          {collegeStore.loading && <LoadingPanel />}

          <div className={`m-2 col-span-4 flex flex-col bg-background ${applyTheme}`}>
            {/* Main Content */}

            <div className='border border-b-0 rounded-t-lg text-barPrimary p-4 text-lg 2xl:text-2xl font-bold uppercase'>
              {t('SCHOOL_INFORMATION_MANAGEMENT')}
            </div>
            <Tabs
              TabIndicatorProps={{ style: { display: "none" } }}
              value={tabValue}
              onChange={handleTabChange}
              className="shadow-lg rounded-b-lg border mb-4"
            >
              {/* {getPermissionValidity(PermissionCategory.SYSTEM, "General", "General")
                && <Tab label={t('GENERAL')} {...tabsProps(0, 0 === tabValue)} />}
              {getPermissionValidity(PermissionCategory.SYSTEM, "General", "EmailService")
                && <Tab label={t('EMAIL_SERVICE')} {...tabsProps(1, 1 === tabValue)} />} */}
              {getPermissionValidity(PermissionCategory.SYSTEM, "General", "Permission")
                && <Tab label={t('PERMISSION')} {...tabsProps(0, 0 === tabValue)} />}
            </Tabs>

            {/* <TabPanel value={tabValue} paddingValue={0} index={0}>
              <VMCard titleComponent={
                <div className={headingStyle}>
                  {t('GENERAL_SETTING')}
                </div>
              }>
                <div className="px-20 2xl:px-40 w-full">
                  <div className="m-8 mb-0">
                    <TextField
                      label={t('SCHOOL_NAME')}
                      variant="outlined"
                      value={collegeName}
                      className={`${classes.textField} w-full`}
                      onChange={(value) => setCollegeName(value.target.value)}
                    />
                    <div className="w-full text-right">
                      <input
                        className="hidden"
                        id="upload-logo-button"
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={(file) => {
                          setLogoFile(file.target.files[0]);
                          setLogoPreview(URL.createObjectURL(file.target.files[0]));

                        }}
                      />
                      <label htmlFor="upload-logo-button">
                        <Button
                          className="bg-buttonPrimary text-white mt-4 p-2 text-xs 2xl:text-md"
                          component="span"
                        >
                          <PublishIcon /><span className="ml">{t('UPLOAD')}</span>
                        </Button>
                      </label>
                    </div>

                  </div>
                </div>

                <div className="grid grid-cols-2 px-20 xl:px-32">
                  <div className={`my-2 col-span-2 ${logoPreview != "" && 'xl:col-span-1'}`}>
                    <div className="text-barPrimary text-xl w-full text-center mb-2">
                      {t('CURRENT_SCHOOL_LOGO')}
                    </div>
                    <div className="h-60 w-64" style={{ margin: '0 auto' }}>
                      <img
                        className="w-full h-full object-cover border p-4"
                        src={typeof window !== "undefined" && localStorage.COLLEGE && JSON.parse(localStorage.COLLEGE).logo}
                        alt="logo"
                      />
                    </div>
                  </div>
                  {
                    logoPreview != "" &&
                    <div className="my-2 col-span-2 xl:col-span-1">
                      <div className="text-barPrimary text-xl w-full text-center mb-2">{t('PREVIEW')}</div>
                      <div className="h-60 w-64" style={{ margin: '0 auto' }}>
                        <img
                          className="w-full h-full object-cover border p-4"
                          src={logoPreview}
                          alt="logo"
                        />
                      </div>
                      <div className="w-full text-center">
                        <button
                          className="text-barPrimary"
                          onClick={() => setLogoPreview("")}
                        >
                          {t('REMOVE')}
                        </button>
                      </div>
                    </div>
                  }
                </div>


                <div className="px-20 text-right py-8 w-full">
                  <Button
                    className="bg-buttonPrimary text-white"
                    component="span"
                    onClick={uploadCollegeLogo}
                  >
                    {t('SAVE')}
                  </Button>
                </div>
              </VMCard>
            </TabPanel> */}

            {/* <TabPanel value={tabValue} paddingValue={0} index={1}>
              <VMCard titleComponent={
                <div className={headingStyle}>
                  {t('EMAIL_SERVICE_SETTING')}
                </div>
              }>
                <div className="px-10 2xl:px-40 grid grid-cols-5 my-10">
                  <div className=
                    "col-span-5 lg:col-span-1 text-buttonPrimary font-bold lg:mx-4 pt-2 lg:text-right"
                  >
                    {t('COLOR_BOARD')}
                  </div>
                  <div className="col-span-5 lg:col-span-2">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      className="bg-background w-full h-full px-2"
                      value={theme}
                      onChange={onChangeTheme}
                    >
                      {THEME_NAME_CONVERSION.map((theme, index) => (
                        <MenuItem key={index} value={theme.default}>
                          {theme.default}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div className="mt-2 lg:mt-0 col-span-3 lg:col-span-2">

                    {
                      THEME_CLASS.filter(tc => tc.name == theme).map((tc, index) => (
                        <div key={index} className="flex">
                          <div className="w-10 h-10 ml-0 lg:ml-2 border"
                            style={{ backgroundColor: tc.background }}
                          />
                          <div className="w-10 h-10 ml-0 lg:ml-2 border"
                            style={{ backgroundColor: tc.barPrimary }}
                          />
                          <div className="w-10 h-10 ml-0 lg:ml-2 border"
                            style={{ backgroundColor: tc.headerBg }}
                          />
                          <div className="w-10 h-10 ml-0 lg:ml-2 border"
                            style={{ backgroundColor: tc.headerTextColor }}
                          />
                          <div className="w-10 h-10 ml-0 lg:ml-2 border"
                            style={{ backgroundColor: tc.button }}
                          />
                        </div>

                      ))
                    }

                  </div>
                </div>

                <div className="px-0 2xl:px-40">
                  <div className="my-8">
                    <div className="w-1/2" style={{ margin: '0 auto' }}>
                      <TextField
                        label={t('EMAIL_SERVICE')}
                        variant="outlined"
                        value={emailServer}
                        className={`${classes.textField} w-full`}
                        style={{ margin: '0 auto' }}
                        onChange={(value) => setEmailServer(value.target.value)}
                      />
                    </div>
                  </div>
                  <div className="my-8">
                    <div className="w-1/2" style={{ margin: '0 auto' }}>
                      <TextField
                        label={t('EMAIL_ACCOUNT')}
                        variant="outlined"
                        value={emailAccount}
                        className={`${classes.textField} w-full`}
                        style={{ margin: '0 auto' }}
                        onChange={(value) => setEmailAccount(value.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="w-1/2" style={{ margin: '0 auto' }}>
                      <TextField
                        label={t('EMAIL_PASSWORD')}
                        type="password"
                        variant="outlined"
                        value={emailPwd}
                        className={`${classes.textField} w-full`}
                        style={{ margin: '0 auto' }}
                        onChange={(value) => setEmailPwd(value.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="w-full text-right px-20 my-8">
                  <Button
                    className="px-4 bg-buttonPrimary text-white mb-4 text-xs 2xl:text-md"
                    component="span"
                    onClick={updateCollegeInfo}
                  >
                    <SaveIcon /> <span className="ml-2 mt">{t('SAVE')}</span>
                  </Button>
                </div>
              </VMCard>
            </TabPanel> */}

            <TabPanel value={tabValue} paddingValue={0} index={0}>
              <VMCard titleComponent={
                <div className={headingStyle}>
                  {t('PERMISSION_SETTING')}
                </div>
              }>
                <div className="px-20 pb-8">
                  <div className="mb-4 text-right">
                    <Button
                      type="button"
                      className="bg-buttonPrimary text-white py-2 text-center cursor-pointer text-sm 2xl:text-md"
                      onClick={() => { setShowModal("create") }}
                    >
                      <GroupAddIcon />
                    </Button>
                  </div>

                  <table className="w-full mb-8">
                    <thead className="bg-barPrimary text-white text-center font-bold">
                      <td className="p-4">{t('USER_ROLE')}</td>
                      <td className="p-4">{t('PERMISSION_STRATEGY')}</td>
                      <td className="p-4">{t('ACTION')}</td>
                    </thead>
                    <tbody className="border border-t-0">
                      {
                        collegeStore.permissionList &&
                        collegeStore.permissionList.map((p, index) => (
                          <tr key={`permission_list_element_${index}`} className={`text-center ${index % 2 != 0 && 'bg-gray-100'}`}>
                            <td className="border-r text-sm p-4 2xl:text-lg">{p.name}</td>
                            <td className="border-r text-sm p-4 2xl:text-lg">
                              {
                                p.permissionStrategy.length > 0 ? p.permissionStrategy.map((ps, index) => (
                                  <div key={`permission_strategy_element_${index}`}>{ps.name}</div>
                                ))
                                  : <div>{t('NO_STRATEGY_YET')}</div>
                              }
                            </td>
                            <td className="border-r text-sm p-4 2xl:text-lg">
                              {
                                p.permissionStrategy.length > 0 ?
                                  <>
                                    <Button
                                      className="bg-buttonPrimary py-1 text-white mx-2"
                                      component="span"
                                      onClick={() => {
                                        setRoleId(p.id);
                                        setUserRoleData(collegeStore.permissionList);
                                        setShowPreview(true);
                                      }}
                                    >
                                      <VisibilityIcon />
                                    </Button>
                                    <Button
                                      className="bg-buttonPrimary py-1 text-white mx-2"
                                      component="span"
                                      onClick={() => onModifyPermssions(p.id)}
                                    >
                                      <EditIcon />
                                    </Button>
                                  </>
                                  : <div>{t('NO_STRATEGY_YET')}</div>
                              }
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>

                {
                  showPreview &&
                  <div className="border-t">
                    <div className={headingStyle}>{t('PERMISSION_STRATEGY')}</div>
                    <PermissionTemplate
                      editLevelAllowed={false}
                      actionAfterUpdate={onCloseSaveModal}
                      defaultUserRoleId={roleId}
                      previewMode={true}
                    />
                    {/* <PermissionSetting
                      role={userRoleData}
                      id={roleId}
                      collegeId={collegeStore.collegeInfo.id}
                      callback={() => { setShowPreview(false) }}
                      callbackAfterSaved={onCloseSaveModal}
                      allowEdit={false}
                    /> */}
                  </div>
                }
              </VMCard>
            </TabPanel>
          </div>

          <CustomisedModal showModal={showModal != ""}>
            {
              showModal == "modify" ?
                <div
                  className="bg-white relative absolute left-1/2 top-1/2 w-5/6 border-barPrimary"
                  style={{ transform: 'translate(-50%, -50%)', borderTopWidth: 20, height: "90vh", overflow: 'auto' }}
                >
                  <div className='sticky flex justify-end top-0'>
                    <Button
                      className="text-barPrimary"
                      component="span"
                      onClick={() => setShowModal("")}
                    >
                      <ClearIcon />
                    </Button>
                  </div>

                  {/* <PermissionSetting
                    role={userRoleData}
                    id={roleId}
                    collegeId={collegeStore.collegeInfo.id}
                    callback={() => { setShowModal("") }}
                    callbackAfterSaved={onCloseSaveModal}
                  /> */}
                  <PermissionTemplate
                    editLevelAllowed={true}
                    actionAfterUpdate={onCloseSaveModal}
                    defaultUserRoleId={roleId}
                    previewMode={false}
                    onCancel={() => setShowModal("")}
                  />
                </div>
                :
                <div
                  className="bg-white absolute left-1/2 top-1/2 w-2/3 border-t-8 border-barPrimary"
                  style={{ transform: 'translate(-50%, -50%)' }}
                >
                  <div className={headingStyle}>
                    {t('CREATE_NEW')}:
                  </div>
                  <OnCreateUserRoleLayout
                    setShowModal={setShowModal}
                    callbackAfterSaved={onCloseSaveModal}
                  />
                </div>
            }

          </CustomisedModal>

        </Layout >
      }
    </>
  )
})

export default GeneralPage;
